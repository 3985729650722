/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import "form";
@import "~ng-pick-datetime/assets/style/picker.min.css";

.ngx-mat-tel-input-container button {
    margin-top: 7px;
}
.table-container {
    height: 100%;
    overflow: auto;
}

table {
    width: 100%;
}

.max-height-table {
    max-height: 600px;
}

.mat-mdc-table .mdc-data-table__row {
    height: 35px!important;
}

.mdc-dialog .mdc-dialog__content {
    padding: 0 10px !important;
}

.readonly {
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
    opacity: 0.7;
}

.supplier-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-bottom: 16px;

    img {
        border-radius: 50%;
        width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.sticky-save-button {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 20px;
    z-index: 1;
}

.sticky-container {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.timePickerDiv {
    background-color: white;
    display: inline-flex;
    border: 1px solid #ccc;
    color: #555;
}

.timeInput {
    border: none;
    color: #555;
    text-align: center;
    width: 60px;
}
