input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.readonly {
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
    opacity: 0.7;
}
